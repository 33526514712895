import dayjs from 'dayjs';
import 'dayjs/locale/pt';

const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(customParseFormat);

dayjs.locale(process.env.VUE_APP_I18N_LOCALE);

export default dayjs;
