import axios from '@/plugins/axios';
import i18n from '@/plugins/i18n';

export default {
  async createPat({ commit, getters }) {
    try {
      const { patForm } = getters;

      // cl.no = 138
      if (patForm.client !== 'S09012858152,7500000-1') {
        delete patForm.name;
        delete patForm.vatNumber;
        delete patForm.phone;
        delete patForm.address;
        delete patForm.postalCode;
        delete patForm.city;
      } else {
        delete patForm.whoAsk;
      }

      await axios.post('api/pat', patForm);

      commit('setAlert', { msg: i18n.t('wot.success'), alertType: 'success' }, { root: true });
    } catch (e) {
      const { errors, message } = e.response?.data || {};

      commit('setErrors', { key: 'pat', data: errors }, { root: true });

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async createIntervention({ commit, getters }, payload) {
    try {
      const { interventionForm } = getters;

      await axios.post('api/intervention', { pastamp: payload, ...interventionForm });

      commit('setAlert', { msg: i18n.t('wot.success'), alertType: 'success' }, { root: true });
    } catch (e) {
      const { errors, message } = e.response?.data || {};

      commit('setErrors', { key: 'intervention', data: errors }, { root: true });

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async editPat({ commit, getters }, payload) {
    try {
      const { patForm } = getters;

      // cl.no = 138
      if (patForm.client !== 'S09012858152,7500000-1') {
        delete patForm.name;
        delete patForm.vatNumber;
        delete patForm.phone;
        delete patForm.address;
        delete patForm.postalCode;
        delete patForm.city;
      } else {
        delete patForm.whoAsk;
      }

      await axios.patch(`api/pat/${payload}`, patForm);

      commit('setAlert', { msg: i18n.t('wot.success'), alertType: 'success' }, { root: true });
    } catch (e) {
      const { errors, message } = e.response?.data || {};

      commit('setErrors', { key: 'pat', data: errors }, { root: true });

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async editIntervention({ commit, getters }, payload) {
    try {
      const { interventionForm } = getters;

      await axios.patch(`api/intervention/${payload}`, interventionForm);

      commit('setAlert', { msg: i18n.t('wot.success'), alertType: 'success' }, { root: true });
    } catch (e) {
      const { errors, message } = e.response?.data || {};

      commit('setErrors', { key: 'intervention', data: errors }, { root: true });

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async fetchPatsList({ commit, getters }, payload) {
    const sortDesc = payload.sortDesc[0] ? 1 : 0;
    const sortBy = payload.sortBy[0];

    const { patsFilter, patsSearch } = getters;

    const params = {
      ...payload,
      sortDesc,
      sortBy,
      paginate: payload.itemsPerPage,
      ...patsFilter,
      search: patsSearch,
    };

    try {
      const response = await axios.get('api/lists/pats', { params });

      const { data } = response;

      commit('setPatsList', data);
    } catch (error) {
      const { message } = error.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async fetchInterventionsList({ commit, getters }, payload) {
    const sortDesc = payload.sortDesc[0] ? 1 : 0;
    const sortBy = payload.sortBy[0];

    const { interventionsFilter, interventionsSearch } = getters;

    const params = {
      ...payload,
      sortDesc,
      sortBy,
      paginate: payload.itemsPerPage,
      ...interventionsFilter,
      search: interventionsSearch,
    };

    try {
      const response = await axios.get('api/lists/interventions', { params });

      const { data } = response;

      commit('setInterventionsList', data);
    } catch (error) {
      const { message } = error.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async fetchInterventions({ commit }, payload) {
    try {
      const response = await axios.get('api/interventions', { params: payload });

      const { data } = response;

      commit('setInterventions', data);
    } catch (error) {
      const { message } = error.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async fetchPat({ commit }, payload) {
    try {
      const response = await axios.get(`api/pat/${payload}`);

      const { data } = response;

      commit('setPatInfo', data);
    } catch (error) {
      const { message } = error.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async fetchWhoAsk({ commit }) {
    try {
      const response = await axios.get('api/who-ask');

      const { data } = response;

      commit('setWhoAsk', data);
    } catch (error) {
      const { message } = error.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async fetchWorks({ commit }) {
    try {
      const response = await axios.get('api/works');

      const { data } = response;

      commit('setWorks', data);
    } catch (error) {
      const { message } = error.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async fetchTechnicians({ commit }) {
    try {
      const response = await axios.get('api/technicians');

      const { data } = response;

      commit('setTechnicians', data);
    } catch (error) {
      const { message } = error.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async fetchIntervention({ commit }, payload) {
    try {
      const response = await axios.get(`api/intervention/${payload}`);

      const { data } = response;

      commit('setInterventionInfo', data);
    } catch (error) {
      const { message } = error.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async deletePat({ commit }, payload) {
    try {
      await axios.delete(`api/pat/${payload}`);

      commit('setAlert', { msg: i18n.t('wot.success'), alertType: 'success' }, { root: true });
    } catch (e) {
      const { message } = e.response.data;

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async deleteIntervention({ commit }, payload) {
    try {
      await axios.delete(`api/intervention/${payload}`);

      commit('setAlert', { msg: i18n.t('wot.success'), alertType: 'success' }, { root: true });
    } catch (e) {
      const { message } = e.response.data;

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
};
