import axios from '@/plugins/axios';
import i18n from '@/plugins/i18n';
import dayjs from '@/plugins/dayjs';

export default {
  async fetchInterventionsData({ commit, getters }) {
    try {
      const { interventionsFilter } = getters;

      const response = await axios.get('api/charts/pats', { params: interventionsFilter });

      const { data } = response;

      const chartData = {
        labels: [],
        datasets: [],
      };

      data.forEach((element) => {
        chartData.labels.push(dayjs(element.data).format('DD/MM/YYYY'));
      });

      chartData.datasets.push(
        {
          label: i18n.tc('fFulfilled', 2),
          data: data.map(({ done }) => done),
          borderColor: '#2E7D32',
          backgroundColor: '#66BB6A',
        },
        {
          label: i18n.t('fNotFulfilled'),
          data: data.map(({ notDone }) => notDone),
          borderColor: '#C62828',
          backgroundColor: '#E57373',
        },
      );

      commit('setInterventionsData', chartData);
    } catch (error) {
      const { message } = error.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
};
