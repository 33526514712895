import { getField } from 'vuex-map-fields';
import i18n from '@/plugins/i18n';

export default {
  getField,
  breadcrumbs: (_, _1, _2, rootGetters) => {
    const home = { exact: true, text: i18n.t('homePage'), to: { name: 'home' } };
    const ordersList = { exact: true, text: i18n.t('ordersList'), to: { name: 'orders' } };
    const patsList = { exact: true, text: i18n.t('patsList'), to: { name: 'pats' } };
    const interventionsList = { exact: true, text: i18n.t('interventionsList'), to: { name: 'interventions' } };
    const createPat = { exact: true, text: i18n.t('createPat'), to: { name: 'createPat' } };
    const pat = {
      exact: true,
      text: `${i18n.tc('patShort', 1)} #${rootGetters['support/patInfo'].nopat}`,
      to: { name: 'patInfo', params: { pastamp: rootGetters['support/patInfo'].pastamp } },
    };
    const createIntervention = { exact: true, text: i18n.t('createIntervention'), to: { name: 'createIntervention' } };
    const intervention = {
      exact: true,
      text: `${i18n.tc('intervention', 1)} #${rootGetters['support/interventionInfo'].mhid}`,
      to: {
        name: 'editIntervention',
        params: { pastamp: rootGetters['support/patInfo'].pastamp, mhstamp: rootGetters['support/interventionInfo'].mhstamp },
      },
    };
    const config = { exact: true, text: i18n.tc('config', 2), to: { name: 'config' } };

    return {
      orders: [
        home,
        ordersList,
      ],
      pats: [
        home,
        patsList,
      ],
      createPat: [
        home,
        patsList,
        createPat,
      ],
      editPat: [
        home,
        patsList,
        pat,
      ],
      patInfo: [
        home,
        patsList,
        pat,
      ],
      createIntervention: [
        home,
        patsList,
        pat,
        createIntervention,
      ],
      editIntervention: [
        home,
        patsList,
        pat,
        intervention,
      ],
      interventions: [
        home,
        interventionsList,
      ],
      config: [
        home,
        config,
      ],
    };
  },
};
