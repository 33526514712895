import axios from '@/plugins/axios';
import i18n from '@/plugins/i18n';

export default {
  async signIn({ commit }, payload) {
    try {
      await axios.get('/sanctum/csrf-cookie');

      const response = await axios.post('/login', payload);

      const { data } = response;

      localStorage.setItem('user', JSON.stringify(data));

      commit('setUser', data);

      commit('setAlertType', 'success', { root: true });
    } catch (e) {
      const { errors, message } = e.response?.data || {};

      commit('setErrors', { key: 'auth', data: errors }, { root: true });

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  autoSignIn({ commit }) {
    const user = localStorage.getItem('user');

    if (user) {
      commit('setUser', JSON.parse(user));
    }
  },
  async logout({ commit }) {
    try {
      // await axios.post('/logout');

      localStorage.removeItem('user');

      commit('setUser', null);
    } catch (e) {
      const { message } = e.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
};
