import { getField } from 'vuex-map-fields';
import i18n from '@/plugins/i18n';

export default {
  getField,
  ordersSearch: ({ ordersSearch }) => ordersSearch,
  ordersFilter: ({ filters }) => filters.orders,
  ordersList: ({ ordersList }) => ordersList,
  activeBostamp: ({ active }) => active.bostamp,
  activeType: ({ active }) => active.type,
  findOrderByBostamp: (_, { ordersList }) => (bostamp) => ordersList.find(
    (element) => element.bostamp === bostamp,
  ),
  fields: (_, { activeType }) => {
    let title;
    let date;
    let check;
    let revert;
    let operator;

    switch (activeType) {
      case 'u_staresta':
        revert = i18n.t('revertEdge');
        title = i18n.t('piecesEdge');
        date = 'u_dtfarest';
        check = 'u_paresta';
        operator = 'u_oparesta';
        break;
      case 'u_stentalh':
        revert = i18n.t('revertNotch');
        title = i18n.t('piecesNotch');
        date = 'u_dtfent';
        check = 'u_pentalhe';
        operator = 'u_opentalh';
        break;
      case 'u_sttemp':
        revert = i18n.t('revertTempering');
        title = i18n.t('piecesTempering');
        date = 'u_dtftempe';
        check = 'u_ptempera';
        operator = 'u_optemper';
        break;
      case 'u_stdecor':
        revert = i18n.t('revertCutDec');
        title = i18n.t('piecesCutDec');
        date = 'u_dtfdecor';
        check = 'u_pdecor';
        operator = 'u_opdecor';
        break;
      case 'u_stinter':
        revert = i18n.t('revertCutInt');
        title = i18n.t('piecesCutInt');
        date = 'u_dtfinter';
        check = 'u_pinter';
        operator = 'u_opinter';
        break;
      case 'u_stselag':
        revert = i18n.t('revertSealing');
        title = i18n.t('piecesSealing');
        date = 'u_dtfselag';
        check = 'u_pselag';
        operator = 'u_opselag';
        break;
      case 'u_stlamina':
        revert = i18n.t('revertLaminate');
        title = i18n.t('piecesLaminate');
        date = 'u_dtflamin';
        check = 'u_plaminad';
        operator = 'u_oplamin';
        break;
      case 'u_stfuro':
        revert = i18n.t('revertHole');
        title = i18n.t('piecesHole');
        date = 'u_dtffuro';
        check = 'u_pfuro';
        operator = 'u_opfuro';
        break;
      case 'u_stfoscag':
        revert = i18n.t('revertMatte');
        title = i18n.t('piecesMatte');
        date = 'u_dtfosca';
        check = 'u_pfoscag';
        operator = 'u_opfoscag';
        break;
      case 'u_stav':
        revert = i18n.t('revertAv');
        title = i18n.t('piecesAv');
        date = 'u_dtfav';
        check = 'u_pav';
        operator = 'u_opav';
        break;
      case 'u_stku':
        revert = i18n.t('revertKu');
        title = i18n.t('piecesKu');
        date = 'u_dtfku';
        check = 'u_pku';
        operator = 'u_opku';
        break;
      default:
        revert = i18n.t('revertCut');
        title = i18n.t('piecesCut');
        date = 'u_dtfcorte';
        check = 'u_pcorte';
        operator = 'u_opcorte';
        break;
    }

    return {
      revert, title, date, check, operator,
    };
  },
  order: (_, { activeBostamp, findOrderByBostamp }) => findOrderByBostamp(activeBostamp),
  rows: (_, { order, fields }) => order.rows.filter((element) => element[fields.check] === 'Sim'),
  rowsRevert: (_, { order, fields }) => order.rows.filter(
    (element) => element[fields.check] === 'Sim' && element[fields.date] !== '1900-01-01',
  ),
  allDone: (_, { rows, fields }) => rows.every(
    (element) => element[fields.check] === 'Sim' && element[fields.date] !== '1900-01-01',
  ),
};
