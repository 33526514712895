import Vue from 'vue';
import Vuex from 'vuex';

import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import auth from './modules/auth/index';
import orders from './modules/orders/index';
import support from './modules/support/index';
import clients from './modules/clients/index';
import chart from './modules/chart/index';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    auth,
    orders,
    support,
    clients,
    chart,
  },
  state: {
    drawerOpen: false,
    msg: '',
    alertType: '',
    isLoading: false,
    errors: {
      auth: [],
      pat: [],
      intervention: [],
    },
    config: JSON.parse(localStorage.getItem('config')) || {
      technician: null,
    },
  },
  getters,
  mutations,
  actions,
});
