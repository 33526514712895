import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export default {
  namespaced: true,
  state: {
    ordersList: [],
    ordersListTotal: 0,
    ordersSearch: '',
    filters: {
      orders: {
        fechada: false,
      },
    },
    dialogs: {
      process: false,
      revert: false,
    },
    active: {
      type: null,
      bostamp: null,
    },
  },
  getters,
  mutations,
  actions,
};
