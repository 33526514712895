/* eslint-disable camelcase */
import { extend, configure } from 'vee-validate';
import {
  required,
  min,
  digits,
  required_if,
  regex,
  email,
} from 'vee-validate/dist/rules';
import i18n from './i18n';

configure({
  defaultMessage: (field, values) => {
    // eslint-disable-next-line no-underscore-dangle, no-param-reassign
    values._field_ = i18n.t(`fields.${field}`);

    // eslint-disable-next-line no-underscore-dangle
    return i18n.t(`validation.${values._rule_}`, values);
  },
});

extend('required', required);
extend('required_if', required_if);
extend('min', min);
extend('digits', digits);
extend('regex', regex);
extend('email', email);
