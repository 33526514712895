import { getField } from 'vuex-map-fields';

export default {
  getField,
  patsSearch: ({ patsSearch }) => patsSearch,
  interventionsSearch: ({ interventionsSearch }) => interventionsSearch,
  patsFilter: ({ filters }) => filters.pats,
  interventionsFilter: ({ filters }) => filters.interventions,
  patForm: ({ patForm }) => patForm,
  interventionForm: ({ interventionForm }) => interventionForm,
  patInfo: ({ patInfo }) => patInfo,
  interventionInfo: ({ interventionInfo }) => interventionInfo,
};
