import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';

import store from '../store/index';
import axios from '../plugins/axios';

Vue.use(VueRouter);
Vue.use(Meta);

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/config',
    name: 'config',
    component: () => import('@/views/Config.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/orders',
    component: () => import(/* webpackChunkName: "orders" */ '@/views/Orders.vue'),
    children: [
      {
        name: 'orders',
        path: '',
        component: () => import(/* webpackChunkName: "orders" */ '@/components/orders/OrdersList.vue'),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
    ],
  },
  {
    path: '/support',
    component: () => import(/* webpackChunkName: "support" */ '@/views/Support.vue'),
    children: [
      {
        name: 'interventions',
        path: 'interventions',
        component: () => import(/* webpackChunkName: "support" */ '@/components/support/InterventionsList.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'createIntervention',
        path: 'pat/:pastamp/create-intervention',
        component: () => import(/* webpackChunkName: "support" */ '@/components/forms/InterventionForm.vue'),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
      {
        name: 'editIntervention',
        path: 'pat/:pastamp/intervention/:mhstamp',
        component: () => import(/* webpackChunkName: "support" */ '@/components/forms/InterventionForm.vue'),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
      {
        name: 'pats',
        path: 'pats',
        component: () => import(/* webpackChunkName: "support" */ '@/components/support/PatsList.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'createPat',
        path: 'create-pat',
        component: () => import(/* webpackChunkName: "support" */ '@/components/forms/PatForm.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'patInfo',
        path: 'pat/:pastamp',
        component: () => import(/* webpackChunkName: "support" */ '@/components/support/PatInfo.vue'),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
      {
        name: 'editPat',
        path: 'edit-pat/:pastamp',
        component: () => import(/* webpackChunkName: "support" */ '@/components/forms/PatForm.vue'),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
    ],
  },
  {
    path: '/charts',
    component: () => import(/* webpackChunkName: "support" */ '@/views/Charts.vue'),
    children: [
      {
        name: 'interventionsChart',
        path: 'interventions',
        component: () => import(/* webpackChunkName: "support" */ '@/components/charts/InterventionsChart.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '*',
    component: () => import('../views/NotFound.vue'),
    name: 'notFound',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, _from, next) => {
  axios.interceptors.response.use((response) => response, async (error) => {
    if (error.response?.status === 401) {
      await store.dispatch('auth/logout');
      next({ name: 'login' });
    }

    return Promise.reject(error);
  });

  store.dispatch('auth/autoSignIn');

  if (to.meta.requiresAuth && !store.getters['auth/isAuthenticated']) {
    next({ name: 'login' });
  } else if (to.meta.requiresUnauth && store.getters['auth/isAuthenticated']) {
    next({ name: 'home' });
  } else {
    next();
  }
});

export default router;
