import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  withCredentials: true,
  timeout: 30000,
});

instance.defaults.withCredentials = true;
instance.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

export default instance;
