import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import pt from 'vuetify/lib/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
  lang: {
    locales: { pt },
    current: process.env.VUE_APP_I18N_LOCALE,
  },
});
