import axios from '@/plugins/axios';
import i18n from '@/plugins/i18n';

export default {
  async fetchClients({ commit }) {
    try {
      const response = await axios.get('api/clients');

      const { data } = response;

      commit('setClients', data);
    } catch (error) {
      const { message } = error.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
};
