<template>
  <v-app>
    <the-sidebar v-if="isAuthenticated"/>
    <the-topbar v-if="isAuthenticated"/>
    <v-main>
      <v-container fluid>
        <wot-breadcrumbs/>
        <router-view/>
      </v-container>
      <wot-fab-scroll/>
    </v-main>
    <wot-snackbar/>
    <wot-loading v-if="isLoading"/>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  components: {
    TheSidebar: () => import(/* webpackChunkName: "navigation" */ '@/components/layout/TheSideBar.vue'),
    TheTopbar: () => import(/* webpackChunkName: "navigation" */ '@/components/layout/TheTopBar.vue'),
    WotSnackbar: () => import(/* webpackChunkName: "wot" */ '@/components/wot/WotSnackbar.vue'),
    WotLoading: () => import(/* webpackChunkName: "wot" */ '@/components/wot/WotLoading.vue'),
    WotFabScroll: () => import(/* webpackChunkName: "wot" */ '@/components/wot/WotFabScroll.vue'),
    WotBreadcrumbs: () => import(/* webpackChunkName: "wot" */ '@/components/wot/WotBreadcrumbs.vue'),
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapFields(['isLoading']),
  },
};
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
}
.v-data-table tbody tr:nth-of-type(odd) {
  background-color: #E1F5FE;
}
.v-data-table tbody td {
  border: 1px solid #CFD8DC;
  max-width: 33vw;
}
.v-data-table .v-expansion-panel {
  background-color: transparent!important;
}
</style>
