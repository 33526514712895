import axios from '@/plugins/axios';
import i18n from '@/plugins/i18n';

export default {
  async fetchOrdersList({ commit, getters }, payload) {
    const sortDesc = payload.sortDesc[0] ? 1 : 0;
    const sortBy = payload.sortBy[0];

    const { ordersFilter, ordersSearch } = getters;

    const params = {
      ...payload,
      sortDesc,
      sortBy,
      paginate: payload.itemsPerPage,
      ...ordersFilter,
      search: ordersSearch,
    };

    try {
      const response = await axios.get('api/lists/orders', { params });

      const { data } = response;

      commit('setOrdersList', data);
    } catch (error) {
      const { message } = error.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async storeProcess({ commit, getters }, payload) {
    try {
      const { activeBostamp, activeType } = getters;

      await axios.post('api/process', { ...payload, bostamp: activeBostamp, type: activeType });

      commit('setAlert', { msg: i18n.t('wot.success'), alertType: 'success' }, { root: true });
    } catch (error) {
      const { message } = error.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async revertProcess({ commit, getters }, payload) {
    try {
      const { activeBostamp, activeType } = getters;

      await axios.post('api/revert-process', { ...payload, bostamp: activeBostamp, type: activeType });

      commit('setAlert', { msg: i18n.t('wot.success'), alertType: 'success' }, { root: true });
    } catch (error) {
      const { message } = error.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
  async updateProcess({ commit }, payload) {
    const { bistamp, data } = payload;

    try {
      await axios.patch(`api/process/${bistamp}`, data);

      commit('setAlert', { msg: i18n.t('wot.success'), alertType: 'success' }, { root: true });
    } catch (error) {
      const { message } = error.response?.data || {};

      commit('setAlert', { msg: message || i18n.t('wot.error'), alertType: 'error' }, { root: true });
    }
  },
};
