import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import App from './App.vue';
import store from './store';
import './registerServiceWorker';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import vuetify from './plugins/vuetify';
import router from './router';
import i18n from './plugins/i18n';
import './plugins/vee-validate';

Vue.config.productionTip = false;

console.log(process.env.VUE_APP_SENTRY_DSN);

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'wot.sultempera.pt', /^\//],
    }),
  ],
  tracesSampleRate: process.env.VUE_APP_SENTRY_RATE,
});

new Vue({
  store,
  vuetify,
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
