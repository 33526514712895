import dayjs from '@/plugins/dayjs';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export default {
  namespaced: true,
  state: {
    interventionsData: {},
    filters: {
      interventions: {
        month: dayjs().format('YYYY-MM'),
      },
    },
  },
  getters,
  mutations,
  actions,
};
