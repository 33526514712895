import { updateField } from 'vuex-map-fields';

export default {
  updateField,
  setPatsList(state, { data, total }) {
    state.patsList = data;
    state.patsListTotal = total;
  },
  setInterventionsList(state, { data, total }) {
    state.interventionsList = data;
    state.interventionsListTotal = total;
  },
  setPatInfo(state, payload) {
    state.patInfo = payload;
  },
  setInterventionInfo(state, payload) {
    state.interventionInfo = payload;
  },
  setInterventions(state, payload) {
    state.interventions = payload;
  },
  setWhoAsk(state, payload) {
    state.whoAsk = payload;
  },
  setTechnicians(state, payload) {
    state.technicians = payload;
  },
  setWorks(state, payload) {
    state.works = payload;
  },
};
