import Vue from 'vue';
import { updateField } from 'vuex-map-fields';

export default {
  updateField,
  setAlert(state, { msg, alertType }) {
    state.msg = msg;
    state.alertType = alertType;
  },
  setErrors(state, { key, data = [] }) {
    Vue.set(state.errors, key, data);
  },
  setAlertType(state, payload) {
    state.alertType = payload;
  },
};
