import dayjs from '@/plugins/dayjs';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export default {
  namespaced: true,
  state: {
    patsList: [],
    patsListTotal: 0,
    interventionsList: [],
    interventionsListTotal: 0,
    patsSearch: '',
    interventionsSearch: '',
    whoAsk: [],
    works: [],
    filters: {
      pats: {
        closed: false,
      },
      interventions: {
        fulfilled: null,
      },
    },
    patForm: {
      date: dayjs().format('YYYY-MM-DD'),
      hour: dayjs().format('HH:mm'),
      technician: JSON.parse(localStorage.getItem('config'))?.technician,
      summary: '',
      description: '',
      internalComunication: '',
      client: '',
      whoAsk: '',
      closed: false,
      name: '',
      vatNumber: '',
      phone: '',
      email: '',
      address: '',
      postalCode: '',
      city: '',
    },
    interventionForm: {
      date: dayjs().format('YYYY-MM-DD'),
      startHour: dayjs().format('HH:mm'),
      endHour: dayjs().format('HH:mm'),
      technician: JSON.parse(localStorage.getItem('config'))?.technician,
      report: '',
      fulfilled: false,
    },
    patInfo: {
      pastamp: 0,
      nopat: 0,
      mh: [],
    },
    interventionInfo: {
      mhstamp: 0,
      mhid: 0,
      pa: {
        pastamp: 0,
      },
    },
    interventions: [],
    technicians: [],
  },
  getters,
  mutations,
  actions,
};
